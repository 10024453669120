@import url('https://fonts.googleapis.com/css2?family=Allura&family=Berkshire+Swash&family=Roboto&family=Roboto+Slab&display=swap');

:root {
    --color-submit-button-1: brown;
    --color-submit-button-2: saddlebrown;
    --color-main-1:#AB911D;
    --color-main-2: black;
    --color-main-3:#B87916;
    --font1:"Berkshire Swash", "Helvetica Neue",fantasy;
    --font2: "Roboto Slab", "Helvetica Neue",fantasy;
    --font3:"Roboto", "Helvetica Neue",fantasy;
    font-size: 15px;
}

main{
    position: absolute;
    top: 0;
    bottom: 100%;
    left:0;
    right: 100%;
    width: 100vw;
    height: 100vh;

}

.body-wrapper{
    min-height: 100%;
}

.app-wrapper {
    position: absolute;
    box-sizing: border-box;

}

.app-container {
    box-sizing: border-box;
    width: 80vw;
    height: 80vh;
}

.board-wrapper {
    box-sizing: border-box;
    border-radius: 70px;
    padding: 25px 25px;
    width: 100%;
    height: 100%;
    transform: translateX(12.5%) translateY(15%);
}

.board-container {
    box-sizing: border-box;
    background-color: white;
    border-radius: 60px;
    padding: 2% 2%;
    font-family: "Roboto", Serif, serif;
    height: 100%;
    overflow: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

    /* Hide scrollbar for Chrome, Safari and Opera */
    .board-container::-webkit-scrollbar {
        display: none;
}

.board-paper {

    width: 100%;
    height: 100%;
    position: absolute;
}

.title-container {
    position: absolute;
    width: 100vw;
}

.title {
    color: var(--color-main-1);
    font-size: 150px;
    position: relative;
    top: -110px;
    right: 0;
    font-family: var(--font1);
    text-shadow: 3px 0 0 #FFF, 3px 3px 0 #FFF, 0 3px 0 #FFF, -3px 3px 0 #FFF, -3px 0 0 #FFF, -3px -3px 0 #FFF, 0 -3px 0 #FFF, 3px -3px 0 #FFF;
    /*text-shadow: 2px 0 10px #FFF, 2px 2px 10px #FFF, 0 2px 10px #FFF, -2px 2px 10px #FFF, -2px 0 10px #FFF, -2px -2px 10px #FFF, 0 -2px 10px #FFF, 2px -2px 10px #FFF;*/
}

/*
.container-flex {
    display: flex;
}

.between{
    justify-content: space-between;
}

.around{
    justify-content: space-around;
}



.row{
    flex-direction: row;
}

.column{
    flex-direction: column;
}

.max{
    width: 100%;
    height: 100%;
}

.w80{
    width: 80%;
    height: 80%;
}

.border {
   border: 2px solid black;
}

.center {
    text-align: center;
}

.centerItem {
    align-items: center;
}

.marginauto{
    margin: auto 0;
}

*/