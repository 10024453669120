@import url(https://fonts.googleapis.com/css2?family=Allura&family=Berkshire+Swash&family=Roboto&family=Roboto+Slab&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
    text-align: center;
    font-size: 40px;
    z-index: 1;
}

.title-container {
    height: 1em;
    pointer-events: none;
}

/*
.footer {
    text-align: center;
}
 */

:root {
    --color-submit-button-1: brown;
    --color-submit-button-2: saddlebrown;
    --color-main-1:#AB911D;
    --color-main-2: black;
    --color-main-3:#B87916;
    --font1:"Berkshire Swash", "Helvetica Neue",fantasy;
    --font2: "Roboto Slab", "Helvetica Neue",fantasy;
    --font3:"Roboto", "Helvetica Neue",fantasy;
    font-size: 15px;
}

main{
    position: absolute;
    top: 0;
    bottom: 100%;
    left:0;
    right: 100%;
    width: 100vw;
    height: 100vh;

}

.body-wrapper{
    min-height: 100%;
}

.app-wrapper {
    position: absolute;
    box-sizing: border-box;

}

.app-container {
    box-sizing: border-box;
    width: 80vw;
    height: 80vh;
}

.board-wrapper {
    box-sizing: border-box;
    border-radius: 70px;
    padding: 25px 25px;
    width: 100%;
    height: 100%;
    transform: translateX(12.5%) translateY(15%);
}

.board-container {
    box-sizing: border-box;
    background-color: white;
    border-radius: 60px;
    padding: 2% 2%;
    font-family: "Roboto", Serif, serif;
    height: 100%;
    overflow: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

    /* Hide scrollbar for Chrome, Safari and Opera */
    .board-container::-webkit-scrollbar {
        display: none;
}

.board-paper {

    width: 100%;
    height: 100%;
    position: absolute;
}

.title-container {
    position: absolute;
    width: 100vw;
}

.title {
    color: #AB911D;
    color: var(--color-main-1);
    font-size: 150px;
    position: relative;
    top: -110px;
    right: 0;
    font-family: "Berkshire Swash", "Helvetica Neue",fantasy;
    font-family: var(--font1);
    text-shadow: 3px 0 0 #FFF, 3px 3px 0 #FFF, 0 3px 0 #FFF, -3px 3px 0 #FFF, -3px 0 0 #FFF, -3px -3px 0 #FFF, 0 -3px 0 #FFF, 3px -3px 0 #FFF;
    /*text-shadow: 2px 0 10px #FFF, 2px 2px 10px #FFF, 0 2px 10px #FFF, -2px 2px 10px #FFF, -2px 0 10px #FFF, -2px -2px 10px #FFF, 0 -2px 10px #FFF, 2px -2px 10px #FFF;*/
}

/*
.container-flex {
    display: flex;
}

.between{
    justify-content: space-between;
}

.around{
    justify-content: space-around;
}



.row{
    flex-direction: row;
}

.column{
    flex-direction: column;
}

.max{
    width: 100%;
    height: 100%;
}

.w80{
    width: 80%;
    height: 80%;
}

.border {
   border: 2px solid black;
}

.center {
    text-align: center;
}

.centerItem {
    align-items: center;
}

.marginauto{
    margin: auto 0;
}

*/

.login-container {
    display: grid;
    grid-template-columns:50% 50%;
}

@media screen and (max-width: 1280px)
{
    .login-container {
        display: inline;
        display: initial;
    }

}

.form {
    margin: 0 auto;
}

.div-title {
    color: var(--color-main-1);
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font2);
    margin-bottom: 12px;
}

.submit-button-wooden{
    display: block;
    color: wheat;
    border:  none;
    padding: 8px;
    width: 150px;
    height: 50px;
    margin: 10px auto;
    font-family: var(--font3);
    font-size: 1rem;
    cursor: auto;
}

.submit-button-wooden:hover {
    filter: contrast(140%);
}

.form > .submit-button-wooden {
    margin-top: 2rem;
}

.form-input {
    display: block;
    width: 85%;
    background-color: darkgrey;
    border-radius: 15px;
    padding-right: 25px;
    border: sandybrown 4px solid;
    height: 20px;
    text-align: center;
    margin: 0 auto;
    outline: none;
}

.form-label {
    display: block;
    margin: 0 auto;
    width: 85%;
    font-family: var(--font2);
    font-size: 1.2rem;

}

.form-div, .form {
    width: 100%;
    margin-top: 5px;
}

.presentation-container{
    font-size: 1.1rem;
}



.presentation-container>p{
    margin: 4px 0;
    text-align: center;
}

.footer-container {
    text-align: center;
    font-weight: bolder;
    margin-top: 20px;
}


@keyframes intro {
    0%   {margin-bottom:100vh}
    100% {margin-bottom: 0}
}
/* Hub Component */

.hub-container {
    display: grid;
    grid-template-columns:30% 70%;
    height: 100%;
}

.mastery-container {
    height: 100%;
}

.create-or-connect-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
}

@media screen and (max-width: 1280px)
{

    .hub-container {
        display: inline;
        display: initial;

    }
}



/* MasteryAnAdventure Component */

.mastery {
    box-sizing: border-box;
    height: 100%;
    color: wheat;
    z-index: 999;
    border: 5px solid var(--color-main-1);
    border-radius: 40px 40px 10px 10px ;
    overflow: hidden;

}

.mastery * {
    box-sizing: border-box;

}

.mastery-title {
    font-family: var(--font2);
    font-size: 2rem;
    margin: 1%;
    height: 10%;
}

.world-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns:50% 50%;
    grid-template-rows: 1fr 1fr;
    height: 88%;
    background-color: wheat;
    overflow: hidden;
}




/* AdventureCard Component */

.world-card {
    padding: 10px;
    overflow: hidden;
    height: 100%;
}

.world-card-title {
    margin: 0;
    z-index: 1 !important;
    color: var(--color-main-2);

}

.world-card-content {
    position: relative;
    height: 83%;
    margin: 0;
    bottom:0;
}

.world-card-button {
    box-sizing: border-box;
    position: absolute;
    bottom: 5px;
    height: 3em;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    color: var(--color-main-2);

}

.world-card-button:hover {
    filter: contrast(140%);
}

.world-card-content > p{
    box-sizing: border-box;
    display: flex;
    text-align: justify;
    align-items: center;
    flex-direction: column;
    flex-basis: auto;
    background-color: rgba(255, 255, 255, 50%);
    color: black;
    font-family: var(--font2);
    font-weight: bolder;
    padding: 1em 1em;
    padding-bottom: 0;
    height: 60%;
    overflow: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.world-card-content>p::-webkit-scrollbar {
    display: none;
}

.world-card-empty-content {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    flex-basis: auto;
    color: var(--color-main-2);
    font-family: var(--font2);
    font-weight: bold;
    padding: 1.1em;
    height: 40%;
    width: 90%;
    margin: 10% auto;
    transform: rotate(15deg);
    font-size: 1.5rem;
}


@media screen and (max-width: 1280px)
{

    .world-card-empty-content {
        font-size: 1.2rem;
    }

}

/* CreateAnAdventure Component */



.create-button-container {
    margin: 0 auto;
    width: 60%;
    background-color: var(--color-main-1);
    border-radius: 40%;
    border:  10px solid gold;

}

.create-button {
    margin: 20%;
    width: 60%;
    background-color: transparent;
}


/* ConnectToAdventure Component */

.connect-button {
    width: 15em ;
    height: 30%;
    margin-top: 30px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}



.character-caracteristics-group {
    display: flex;
    flex-wrap:wrap;
    margin: auto;
}

@media screen and (max-width: 1280px) {
    .character-caracteristics-group {
        margin-top: 20px;
    }
}

.character-caracteristics-one-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap:wrap;
    width: 33%;
    align-content: center;
    margin-top: 15px;

}

.character-caracteristics-one-label {
    display: block;
    width: 40%;
    margin: auto 0;

}

.character-caracteristics-one-input {
    width: 1em;
    height: 1em;
    padding: 1em;
    border-radius: 100%;
    text-align: center;
    border: 5px solid var(--color-main-1);
    }

.character-caracteristics-one-input:active,
.character-caracteristics-one-input:focus
{
    border: 5px solid var(--color-main-3);
    outline: red;
}

.character-caracteristics-stock-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    width: 30%;
    margin:10px auto;
}

.character-caracteristics-stock-container  p {
    text-align: center;
}

.character-caracteristics-stock-value {

    border-radius: 100%;
    background-color: var(--color-main-1);
    border: 5px solid var(--color-main-2);
    color: var(--color-main-2);
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: center;
    margin: auto;

}



.character-resume-part1 {
    display: grid;
    grid-template-columns:40% 60%;
}

.character-resume-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.character-resume-name-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin:  auto;
}

.character-resume-button-group {
    display: flex;
}

.character-resume-button-container {
    width: 30%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
}

.character-resume-button {
    width: 30px;
    height: 30px;
}

.character-resume-button:hover {
    filter: contrast(140%);
}

.before {
    transform: rotate(180deg);
}

.after-div, .before-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.character-resume-avatar-div {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    background-color: black;
    border: 5px solid var(--color-main-1);
    margin: 0 auto;
}

.character-resume-avatar{
    width: 100%;



}

.character-resume-part2 {
    display: grid;
    grid-template-columns:33% 33% 33%;

}

.character-creation-powers {
    padding: 0 5%;
    text-align: justify;
}
.character-creation-skills {
    padding: 0 5%;
    text-align: justify;
}
.character-creation-resume{
    display: grid;
    grid-template-columns:50% 50%;

}


.character-creation-special-action{
    display: grid;
    grid-template-columns:50% 50%;

}

@media screen and (max-width: 1280px) {
    .character-creation-special-action {
        display: inline;
        display: initial;
    }

    .character-creation-special-action > * {
        margin-top: 40px;
    }


    .character-creation-resume {
        display: inline;
        display: initial;
    }
}

.full {
    text-align: center;
    vertical-align: center;
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    width: 100%;
}

.character-creation-submit {
    margin-top: 30px;
    width: 60%;
    height: 7rem;
    font-size: 1.5rem;
    background-color: transparent;
}

.character-creation-submit:hover {
filter: brightness(150%);
}




.adventure-creation-form {
    display: grid;
    grid-template-columns:60% 40%;

}

.adventure-creation-form-alert {
    margin: auto 0;
}


.invalid, .valid {
    color: var(--color-main-2);
    background-color: var(--color-main-1);
    padding: 5px;
    text-align: center;
    width: 70%;
    margin: 10px auto;
    border-radius: 15px;
    border: 3px solid var(--color-main-2);

}

.adventure-creation-invitation-div, .adventure-creation-description-div {
    margin: 20px auto;
}

.adventure-creation-invitation-div {
    height: 70px;
}

.adventure-creation-description-input {
    width: 90%;
    margin: 0 auto;
    display: block;
    height: 60px;
}

.adventure-creation-invitation-link {
    text-align: center;
}

.adventure-creation-button {
    width: 25%;
    height: 15%;
    font-size: 1.5rem;
}
.playablecharacters {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    border-radius: 50px 50px 0 0;
    overflow: hidden;
}

.playablecharacters-slot {
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-main-1);
    background-color: var(--color-main-1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;

}

.playablecharacters-slot-avatar-div {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    background-color: black;
    border: 5px solid wheat;
    margin: 0 auto;
}

.playablecharacters-slot-avatar{
    width: 100%;
}

.playablecharacters-slot-group {
    display: flex;
    flex-direction: column;
}

.playablecharacters-slot-group > p {
    margin: 0;
    padding: 4px;
}

@media screen and (max-width: 1280px) {
    .playablecharacters-slot-avatar-div {
        width: 40px;
        height: 40px;
    }
    .playablecharacters-slot-group > p {
        padding: 2px;
        font-size: 0.8rem;
    }
    .playablecharacters {
    margin-top: 20px;
    }
    }

.npc-container {
    height: 200px;
    overflow: scroll;
    background-color: var(--color-main-3);
    border-radius: 10px;
    padding: 5px;


    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.npc-container::-webkit-scrollbar {
    display: none;
}


.npc-div {
    margin: 0;
}

.npc-separator {
border: 1.2px solid black;
}

.gm_npc .div-title:hover {
    color: var(--color-main-2);
}
.equipment-container {
    position: relative;
}

.equipment-main:hover{
    color: var(--color-main-1);
    text-decoration: underline;
}

.equipment-group {
    box-sizing: border-box;
    position: absolute;
    height: 250px;
    border: 5px solid var(--color-main-1);
    border-radius: 20px;
    top: -250px;
    width: 100%;
    background-color: wheat;
}
.specialActions-container {
    margin-top: 20px;

}

.specialActions-headers{
    display: flex;
    justify-content: space-around;
}

.specialActions-container .div-title:hover{
    color: var(--color-main-2);
}

@media screen and (max-width: 1600px)
{

    .div-title{
        font-size: 1rem !important;
    }

}

.specialActions-headers-active {
    -webkit-text-decoration: underline black 3px;
            text-decoration: underline black 3px;
}

.specialActions-options-container {
    height: 250px;
    background-color: var(--color-main-1);
    overflow: scroll;
    padding: 5px;
    text-align: justify;
    border-radius: 10px;
    margin-top: 10px;


    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.specialActions-options-container::-webkit-scrollbar {
    display: none;
}

.specialActions-options {
    margin-bottom: 30px;
    padding: 5px;
}

.specialActions-target-div {
    text-align: center;
    margin-bottom: 5px;
}

.specialActions-options-title {
    text-align: center;
    font-weight: bold;
}

.specialActions-button-submit {
    background-color: transparent !important;
    height: 80%;
}
.chatzone-container {
    padding: 20px;
    border-radius: 60px;
}

.chatzone-messages-container {
    height: 400px;
    overflow: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.chatzone-messages-container::-webkit-scrollbar {
    display: none;
}

.chatzone-writing-input {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    visibility: hidden;
}

.chatzone-writing-label-group {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

.chatzone-writing-label {
    width: 40%;
}

.chatzone-writing-label-active {
    -webkit-text-decoration: underline black 3px;
            text-decoration: underline black 3px;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    width: 40%;
}

.chatzone-writing-textarea-div {
    height: 80px;
    display: flex;
    flex-direction: row;
}

.chatzone-writing-select {
    height: 50px;
}

.chatzone-writing-textarea-input {
    height: 90%;
    width: 90%;
    resize: none;
    margin: 5px auto 0 auto;
}

.chatzone-writing-textarea-button {
    background-color: transparent;
    width: 50%;
    height: 40px;
    margin-top: 10px;
}

/* MessageSlot Component */

.messageslot-container {
    padding: 1px;
    margin-bottom: 15px;
    border-radius: 10px;
}

.messageslot-header {
    font-weight: bold;
    text-align: center;
}

.messageslot-content {
    margin : 1%;
    text-align: justify;
}

.message_player {
    background-color: #FFFFFF80;
}

.message_system {
    background-color: #00000080
}

.message_npc {
    background-color: #AB911D80;
}

.private_message {
    font-size:1.2rem;
}

/* For GM Chat Zone */

.chatzone-freerole-label {
    margin-top: 10px;
    display: block;
    text-align: center;
    width: 100%;
}

.chatzone-freerole-label:hover{
    font-weight: bold;
}

.chatzone-freerole-choice-div {
    width: 50%;
    margin: auto;
}
.chatzone-freerole-choice-div > * {
    margin: 10px;
}

.npc-creation-div {
    margin: 10px 0;
    position: relative;
}

.npc-creation-label {
    position: relative;
    left: 40px;
}

.npc-creation-input {
    position: absolute;
    right: 20px;
}

.npc-creation-textarea-div{
    height: 100px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.npc-creation-textarea-input {
    width: 90%;
    height: 50%;
    resize: none;
    margin: 5px auto 0 auto;
}

.npc-creation-button-submit {
    border:none;
    background-color: transparent;
    color: wheat;
    width: 200px;
    margin: 5px auto;
}
.quests-container {
    box-sizing: border-box;
    margin: 2%;
    height: 50%;
    padding: 5px;
    border: 5px solid var(--color-main-2);
    border-radius: 30px;
    overflow: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.quests-container::-webkit-scrollbar {
    display: none;
}

.quests-sub-title {
font-size: 80%;
}

.quests-sub-title:hover {
    color: var(--color-main-2);
}

.quests-content {
    text-align: justify;
}
.inventory-container {
    position: relative;
}

.inventory-main:hover{
    color: var(--color-main-1);
    text-decoration: underline;
}

.inventory-group {
    box-sizing: border-box;
    position: absolute;
    height: 250px;
    border: 5px solid var(--color-main-1);
    border-radius: 20px;
    top: -250px;
    width: 100%;
    background-color: wheat;
}

.inventory-item-content {
    width: 80%;
    text-align: left;
    margin-left: 10px;
    position: relative;
}

.inventory-item-button {
    text-align: center;
    position: absolute;
    width: 80px;
    padding: 5px;
    color: wheat;
    right: -10%;
    background-color: transparent;
    border: none;
}

.inventory-item-button:hover {
    filter: contrast(140%);
}
.places-container {
    box-sizing: border-box;
    margin: 2%;
    height: 50%;
    padding: 5px;
    border: 5px solid var(--color-main-3);
    border-radius: 30px;
    overflow: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.places-container::-webkit-scrollbar {
    display: none;
}

.places-sub-title {
font-size: 80%;
}

.places-sub-title:hover {
    color: var(--color-main-2);
}

.places-content {
    text-align: justify;
}


.askdice-container {
    position: relative;
    width: 97%;
    margin: auto;
}

.askdice-main:hover{
    color: var(--color-main-1);
    text-decoration: underline;
}

.askdice-group {
    box-sizing: border-box;
    position: absolute;
    height: 400px;
    border: 5px solid var(--color-main-1);
    border-radius: 20px;
    top: -400px;
    width: 100%;
    background-color: wheat;
}

.askdice-action-choice {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.askdice-action-description {
    padding: 10px;
    display: block;
}

.askdice-action-input {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    visibility: hidden;
}


.askdice-action-label:hover {
    font-weight: bold;
}

.askdice-writing-textarea-input {
    height: 90%;
    width: 90%;
    resize: none;
    margin: 5px auto 0 auto;
}

.askdice-closebutton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: var(--color-main-1);
    border: none;
    padding: 5px;
    border-radius: 100%;
}

.askdice-closebutton:hover{
    font-weight: bold;
}

.askdice-button-submit {
    background-color: transparent;
    color: wheat;
    border: none;
    margin: 10px;
    width: 120px;
    height: 30px;
}

.askdice-button-submit:hover {
    filter: contrast(140%);
}
.gamezone {
    display: grid;
}

.gamezone-center {
    display: grid;
    grid-template-columns: 30% 40% 30%;
}

.gamezone-center-column {
    display: flex;
    flex-direction: column;
}

.gamezone-footer {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    text-align: center;
}

@media screen and (max-width: 1280px)
{

    .gamezone-center {
        display: inline;
        display: initial;
    }

}
.actionswaiting-container {
    border: 5px solid var(--color-main-1);
    border-radius: 0 0 20px 20px;
    height: 300px;
    overflow: hidden;
}

.actionswaiting-title {
    margin: 5px auto;
}

.actionswaiting-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    overflow-x: scroll;
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.actionswaiting-group::-webkit-scrollbar {
    display: none;
}


.actionslot-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 250px;
    text-align: center;
    border: 5px solid var(--color-main-2);
    margin: 0 5px;
    border-radius: 20px;
    background-color: wheat;
}

.actionslot-container-active {
    width: 600px;
    height: 250px;

}

.actionslot-title {
    margin: 0 auto;
    font-size: 1rem;
}

.actionslot-description {
    text-align: justify;
    margin: 0 5%;
}

.actionslot-description-name {
    font-weight: bold;
}

.gamezone-row {
    overflow-x: hidden;
    z-index: 1;
}

.actionslot-button {
    margin: 0 10px;
    display: inline-block;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: transparent;
    width: 35%;
    height: 30px;
    margin-top: 10px;
}
