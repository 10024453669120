

.character-resume-part1 {
    display: grid;
    grid-template-columns:40% 60%;
}

.character-resume-avatar-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.character-resume-name-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin:  auto;
}

.character-resume-button-group {
    display: flex;
}

.character-resume-button-container {
    width: 30%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
}

.character-resume-button {
    width: 30px;
    height: 30px;
}

.character-resume-button:hover {
    filter: contrast(140%);
}

.before {
    transform: rotate(180deg);
}

.after-div, .before-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.character-resume-avatar-div {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    background-color: black;
    border: 5px solid var(--color-main-1);
    margin: 0 auto;
}

.character-resume-avatar{
    width: 100%;



}

.character-resume-part2 {
    display: grid;
    grid-template-columns:33% 33% 33%;

}
