.gamezone {
    display: grid;
}

.gamezone-center {
    display: grid;
    grid-template-columns: 30% 40% 30%;
}

.gamezone-center-column {
    display: flex;
    flex-direction: column;
}

.gamezone-footer {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    text-align: center;
}

@media screen and (max-width: 1280px)
{

    .gamezone-center {
        display: initial;
    }

}