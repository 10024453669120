.equipment-container {
    position: relative;
}

.equipment-main:hover{
    color: var(--color-main-1);
    text-decoration: underline;
}

.equipment-group {
    box-sizing: border-box;
    position: absolute;
    height: 250px;
    border: 5px solid var(--color-main-1);
    border-radius: 20px;
    top: -250px;
    width: 100%;
    background-color: wheat;
}