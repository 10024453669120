.places-container {
    box-sizing: border-box;
    margin: 2%;
    height: 50%;
    padding: 5px;
    border: 5px solid var(--color-main-3);
    border-radius: 30px;
    overflow: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.places-container::-webkit-scrollbar {
    display: none;
}

.places-sub-title {
font-size: 80%;
}

.places-sub-title:hover {
    color: var(--color-main-2);
}

.places-content {
    text-align: justify;
}

