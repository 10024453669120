.npc-container {
    height: 200px;
    overflow: scroll;
    background-color: var(--color-main-3);
    border-radius: 10px;
    padding: 5px;


    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.npc-container::-webkit-scrollbar {
    display: none;
}


.npc-div {
    margin: 0;
}

.npc-separator {
border: 1.2px solid black;
}

.gm_npc .div-title:hover {
    color: var(--color-main-2);
}