/* Hub Component */

.hub-container {
    display: grid;
    grid-template-columns:30% 70%;
    height: 100%;
}

.mastery-container {
    height: 100%;
}

.create-or-connect-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0;
}

@media screen and (max-width: 1280px)
{

    .hub-container {
        display: initial;

    }
}



/* MasteryAnAdventure Component */

.mastery {
    box-sizing: border-box;
    height: 100%;
    color: wheat;
    z-index: 999;
    border: 5px solid var(--color-main-1);
    border-radius: 40px 40px 10px 10px ;
    overflow: hidden;

}

.mastery * {
    box-sizing: border-box;

}

.mastery-title {
    font-family: var(--font2);
    font-size: 2rem;
    margin: 1%;
    height: 10%;
}

.world-container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns:50% 50%;
    grid-template-rows: 1fr 1fr;
    height: 88%;
    background-color: wheat;
    overflow: hidden;
}




/* AdventureCard Component */

.world-card {
    padding: 10px;
    overflow: hidden;
    height: 100%;
}

.world-card-title {
    margin: 0;
    z-index: 1 !important;
    color: var(--color-main-2);

}

.world-card-content {
    position: relative;
    height: 83%;
    margin: 0;
    bottom:0;
}

.world-card-button {
    box-sizing: border-box;
    position: absolute;
    bottom: 5px;
    height: 3em;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    color: var(--color-main-2);

}

.world-card-button:hover {
    filter: contrast(140%);
}

.world-card-content > p{
    box-sizing: border-box;
    display: flex;
    text-align: justify;
    align-items: center;
    flex-direction: column;
    flex-basis: auto;
    background-color: rgba(255, 255, 255, 50%);
    color: black;
    font-family: var(--font2);
    font-weight: bolder;
    padding: 1em 1em;
    padding-bottom: 0;
    height: 60%;
    overflow: scroll;

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* Hide scrollbar for Chrome, Safari and Opera */
.world-card-content>p::-webkit-scrollbar {
    display: none;
}

.world-card-empty-content {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    flex-basis: auto;
    color: var(--color-main-2);
    font-family: var(--font2);
    font-weight: bold;
    padding: 1.1em;
    height: 40%;
    width: 90%;
    margin: 10% auto;
    transform: rotate(15deg);
    font-size: 1.5rem;
}


@media screen and (max-width: 1280px)
{

    .world-card-empty-content {
        font-size: 1.2rem;
    }

}

/* CreateAnAdventure Component */



.create-button-container {
    margin: 0 auto;
    width: 60%;
    background-color: var(--color-main-1);
    border-radius: 40%;
    border:  10px solid gold;

}

.create-button {
    margin: 20%;
    width: 60%;
    background-color: transparent;
}


/* ConnectToAdventure Component */

.connect-button {
    width: 15em ;
    height: 30%;
    margin-top: 30px;
}